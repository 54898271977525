//sage-page-wrapper.js
import React from 'react'

const PageWrapper = ({ children, links, title, showNavigation = true }) => {
	return (
		<div className='dark:bg-main-dark-bg  bg-main-bg dark:text-white font-montserrat '>
			<div className='max-w-6xl mx-auto'>{children}</div>
		</div>
	)
}

export default PageWrapper
