//project-map.js
import React, { useEffect, useState } from 'react'
import { PageWrapper } from '../../components'
import { useSelector } from 'react-redux'
import InitialForm from '../../components/page-components/apps/project-map/initial-form'
import Loading from '../../components/page-components/apps/project-map/loading'
import Error from '../../components/page-components/apps/project-map/error'
import Map from '../../components/page-components/apps/project-map/map'

const ProjectMap = () => {
	const [showForm, setShowForm] = useState(true)
	const eventsRdx = useSelector((state) => state.events)
	const { data, loading, error } = eventsRdx

	useEffect(() => {
		if (error || loading || data?.length === 0) {
			setShowForm(true)
		} else setShowForm(false)

		return () => {}
	}, [error, data, loading])

	const showFormHandler = () => {
		setShowForm((prev) => !prev)
	}
	return (
		<PageWrapper showNavigation={false}>
			<InitialForm showForm={showForm} onShowForm={showFormHandler} />
			{loading && <Loading />}
			{data && <Map data={data} />}
			{error && <Error error={error} />}
		</PageWrapper>
	)
}

export default ProjectMap
