import React from 'react'

const TableItem = ({
	jobId,
	weekCompleted,
	classId,
	className,
	revenue,
	cogs,
	expenses,
	grossMargin,
	grossMarginPercentage,
	transactions,
}) => {
	return (
		<div className='grid grid-cols-10 items-center'>
			<div>{jobId || ''}</div>
			<div>
				{new Date(weekCompleted).toLocaleString('en-US', {
					month: 'short', // "Feb" for February
					day: '2-digit', // "10" for the tenth day
					timeZone: 'America/New_York',
				})}
			</div>
			<div>{classId || ''}</div>
			<div>{className || ''}</div>
			<div>
				{revenue
					? revenue
							.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
							.slice(1)
					: ''}
			</div>
			<div>
				{cogs
					? cogs
							.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
							.slice(1)
					: ''}
			</div>
			<div>
				{expenses
					? expenses
							.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
							.slice(1)
					: ''}
			</div>
			<div>{grossMargin ? grossMargin.toFixed(2) : ''}</div>
			<div>
				{grossMarginPercentage ? grossMarginPercentage.toFixed(2) + '%' : ''}
			</div>
			<div>{transactions?.length > 0 ? transactions.length : ''}</div>
		</div>
	)
}

export default TableItem
