// table.js
import React from 'react'
import TableItem from './table-item'

const Table = ({ data }) => {
	return (
		<div className='py-2 text-xs grid gap-1'>
			<div className='text-sm'>
				The following cogs do not have a job associated with them.
			</div>
			{/* Header */}
			<div className='grid grid-cols-7 font-bold'>
				<div className='text-center'>Record Type</div>
				<div className='text-center'>Department</div>
				<div className='text-center'>Account</div>
				<div className='text-center'>Amount</div>
				<div className='text-center'>Date</div>
				<div className='text-center'>Vendor</div>
				<div className='text-center'>Description</div>
			</div>
			{/* Rows */}
			{data?.data?.length > 0 &&
				data.data.map((row, idx) => <TableItem key={idx} row={row} />)}
		</div>
	)
}

export default Table
