import React, { useEffect, useState } from 'react'
// import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createJobSiteVisit } from '../../../../redux/actions'
import { useDispatch } from 'react-redux'
import { resetAppItem } from '../../../../redux/actions/appActions'
import InitialFormHeader from './initial-form-header'
import InitialFormCategory from './initial-form-category'
import { CATEGORIES } from './initial-default-categories'
// import CameraControls from './initial-form-camera-controls'

const InitialForm = ({ showForm, data }) => {
	const [showCategory, setShowCategory] = useState(null)
	const [categories, setCategories] = useState(CATEGORIES)
	const [isFormValid, setIsFormValid] = useState(false)
	const [jobId, setJobId] = useState('')
	const [salesRep, setSalesRep] = useState('')
	const [email, setEmail] = useState('')
	const [supervisor, setDisplayName] = useState('')
	const [tearOffCrew, setTearOffCrew] = useState('')
	const [installCrew, setInstallCrew] = useState('')
	const [siteZip, setSiteZip] = useState('')
	// const [showPhotoSection, setShowPhotoSection] = useState(false)

	// const [cameraStream, setCameraStream] = useState(null)
	// const [capturedImages, setCapturedImages] = useState([])
	// const [selectedImage, setSelectedImage] = useState(null)

	const location = useLocation()
	const dispatch = useDispatch()
	// const dispatch = useDispatch()

	useEffect(() => {
		if (location && location.search) {
			const params = new URLSearchParams(location.search)
			// console.log(params)
			const supervisor = params.get('dn')
			const email = params.get('em')
			const salesRep = params.get('est')
			const tearOffCrew = params.get('toc')
			const installCrew = params.get('ic')
			const jobId = params.get('jobid')
			const zip = params.get('zip')
			setJobId(jobId)
			setSiteZip(zip)
			setDisplayName(supervisor)
			setEmail(email)
			setSalesRep(salesRep)
			setTearOffCrew(tearOffCrew)
			setInstallCrew(installCrew)
		}
		return () => {}
	}, [location])

	useEffect(() => {
		if (data?.message) {
			setCategories(CATEGORIES)
		}
		const timer = setTimeout(() => {
			if (data?.data) {
				dispatch(resetAppItem('createJobSiteVisit'))
			}
		}, 5000)

		return () => clearTimeout(timer)
	}, [data])
	// useEffect(() => {
	// 	return () => {
	// 		// Cleanup camera on component unmount
	// 		stopCamera()
	// 	}
	// }, [])

	// const startCamera = async () => {
	// 	try {
	// 		const stream = await navigator.mediaDevices.getUserMedia({ video: true })
	// 		setSelectedImage(null)
	// 		setCameraStream(stream)
	// 		const videoPlayer = document.querySelector('.video-player')
	// 		videoPlayer.srcObject = stream
	// 	} catch (error) {
	// 		console.error('Error accessing the camera: ', error)
	// 	}
	// }

	// const captureImage = () => {
	// 	const videoPlayer = document.querySelector('.video-player')
	// 	const canvas = document.createElement('canvas')
	// 	canvas.width = videoPlayer.videoWidth
	// 	canvas.height = videoPlayer.videoHeight
	// 	canvas
	// 		.getContext('2d')
	// 		.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
	// 	canvas.toBlob((blob) => {
	// 		const imageUrl = URL.createObjectURL(blob)
	// 		setCapturedImages((prevImages) => [...prevImages, imageUrl])
	// 	}, 'image/jpeg')
	// }

	// const stopCamera = () => {
	// 	if (cameraStream) {
	// 		cameraStream.getTracks().forEach((track) => track.stop())
	// 		setCameraStream(null)
	// 	}
	// }

	const checkFormValidation = () => {
		const allValid = categories.every((category) => category.isValidated)
		setIsFormValid(allValid)
	}
	useEffect(() => {
		checkFormValidation()
	}, [categories])

	const updateCategoryItemRank = (categoryName, itemName, rankValue) => {
		setCategories((prevCategories) =>
			prevCategories.map((category) => {
				if (category.name === categoryName) {
					const updatedItems = category.items.map((item) =>
						item.name === itemName ? { ...item, rank: rankValue } : item,
					)
					const isValidated = updatedItems.every((item) => item.rank !== null)
					return { ...category, items: updatedItems, isValidated: isValidated }
				}
				return category
			}),
		)
	}
	const showCategoryHandler = (name) => {
		if (showCategory === name) {
			setShowCategory(null)
		} else setShowCategory(name)
	}

	const handleCommentsChange = (value, categoryName) => {
		setCategories((categories) =>
			categories.map((category) => {
				if (category.name === categoryName) {
					return { ...category, comments: value }
				}
				return category
			}),
		)
	}
	// const selectImageHandler = (index) => {
	// 	stopCamera()
	// 	setSelectedImage(index)
	// }
	// const deleteImageHandler = (index) => {
	// 	setCapturedImages((prevImages) => prevImages.filter((_, i) => i !== index))
	// 	if (selectedImage === index) {
	// 		setSelectedImage(null) // Clear selection if the deleted image was selected
	// 	}
	// }
	// const showPhotoSectionHandler = () => {
	// 	setShowPhotoSection((prev) => !prev)
	// }
	const submitHandler = async (e) => {
		e.preventDefault()

		// THIS IS FOR THE FORM DATA

		// const formData = new FormData()

		// // Append all text-based fields
		// formData.append('jobId', jobId)
		// formData.append('salesRep', salesRep)
		// formData.append('supervisor', supervisor)
		// formData.append('supervisorEmail', email)
		// formData.append('tearOffCrew', tearOffCrew)
		// formData.append('installCrew', installCrew)
		// formData.append('siteZip', siteZip)
		// formData.append('categories', JSON.stringify(categories)) // Since categories is likely a complex object

		// // Append images as blobs
		// for (const imageUrl of capturedImages) {
		// 	const blob = await fetch(imageUrl).then((res) => res.blob())
		// 	formData.append('images', blob)
		// }

		// // Dispatch the Redux action
		// dispatch(createJobSiteVisit(formData))

		// console.log('Dispatch')
		dispatch(
			createJobSiteVisit({
				jobId,
				salesRep,
				supervisor,
				supervisorEmail: email,
				tearOffCrew,
				installCrew,
				siteZip,
				categories,
			}),
		)
	}
	return (
		<div
			className={` p-10 rounded-xl bg-romanLight3 dark:bg-romanDark2 md:col-span-1 `}
		>
			<div className='text-lg pb-4'>
				<div className='flex justify-between'>
					<h4 className='font-semibold opacity-90'>
						{'Submit Supervisor Job Visit'}
					</h4>
					{/* <div
						className='p-2 hover:bg-romanLight4 dark:hover:bg-romanDark3 rounded-full'
						onClick={() => onShowForm(isFormValid)}
					>
						{showForm ? ICONS.default.minus : ICONS.default.downChevron}
					</div> */}
				</div>
				<div className='opacity-50'>{'Production Tools'}</div>
			</div>
			{showForm && (
				<form onSubmit={submitHandler} className='space-y-4'>
					{/* {error && (
						<p className='text-romanRed2 dark:text-romanGold1 text-sm'>
							{error}
						</p>
					)} */}
					<InitialFormHeader
						jobId={jobId}
						salesRep={salesRep}
						supervisor={supervisor}
						siteZip={siteZip}
						email={email}
						tearOffCrew={tearOffCrew}
						installCrew={installCrew}
					/>
					{categories.map((category) => (
						<InitialFormCategory
							key={category.name}
							category={category}
							showCategory={showCategory}
							onShowCategory={showCategoryHandler}
							onUpdateCategoryItemRank={updateCategoryItemRank}
							onCommentsChange={handleCommentsChange}
						/>
					))}

					{/* <CameraControls
						startCamera={startCamera}
						stopCamera={stopCamera}
						captureImage={captureImage}
						cameraStream={cameraStream}
						capturedImages={capturedImages}
						showPhotoSection={showPhotoSection}
						onShowPhotoSection={showPhotoSectionHandler}
						selectedImage={selectedImage}
						onSelectImage={selectImageHandler}
						onDeleteImage={deleteImageHandler}
					/> */}

					<button
						type='submit'
						disabled={!isFormValid}
						className={`w-full p-2 rounded font-semibold ${
							isFormValid
								? 'bg-romanRed2 dark:bg-romanGold1 text-romanDarkTextActive dark:text-romanLightTextActive   hover:bg-romanRed1 dark:hover:bg-romanGold2'
								: 'border-1 border-romanRed2 dark:border-romanGold1 text-romanLightTextInactive dark:text-romanDarkTextInactive'
						}`}
					>
						{isFormValid ? 'Submit' : 'Complete Form'}
					</button>
				</form>
			)}
		</div>
	)
}

export default InitialForm
