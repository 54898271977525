import React from 'react'

const ClassSummaryItem = ({
	className,
	totalRevenue,
	totalCogs,
	totalExpenses,
	totalGM,
	totalGMPercent,
}) => {
	return (
		<div className='grid grid-cols-10 items-center'>
			<div>{className || ''}</div>
			<div>
				{totalRevenue
					? totalRevenue
							.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
							.slice(1)
					: ''}
			</div>
			<div>
				{totalCogs
					? totalCogs
							.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
							.slice(1)
					: ''}
			</div>
			<div>
				{totalExpenses
					? totalExpenses
							.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
							.slice(1)
					: ''}
			</div>
			<div>{totalGM ? totalGM.toFixed(2) : ''}</div>
			<div>{totalGMPercent ? totalGMPercent.toFixed(2) + '%' : ''}</div>
		</div>
	)
}

export default ClassSummaryItem
