import React, { useEffect, useState } from 'react'
import { PageWrapper } from '../../components'
// import { useSelector } from 'react-redux'
import InitialForm from '../../components/page-components/apps/supervisor-job-visit/initial-form'
import Loading from '../../components/page-components/apps/supervisor-job-visit/loading'
import Error from '../../components/page-components/apps/supervisor-job-visit/error'
import Success from '../../components/page-components/apps/supervisor-job-visit/success'
import { useSelector } from 'react-redux'

const SupervisorJobVisit = () => {
	// const [showForm, setShowForm] = useState(true)
	const createJobSiteVisitRdx = useSelector((state) => state.createJobSiteVisit)
	const { data, loading, error } = createJobSiteVisitRdx

	// useEffect(() => {
	// 	if (error || loading || data?.length === 0) {
	// 		setShowForm(true)
	// 	} else setShowForm(false)

	// 	return () => {}
	// }, [error, data, loading])

	// useEffect(() => {
	// 	console.log(createJobSiteVisitRdx)

	// 	return () => {}
	// }, [createJobSiteVisitRdx])

	// const showFormHandler = () => {
	// 	setShowForm((prev) => !prev)
	// }
	return (
		<PageWrapper showNavigation={false}>
			<InitialForm
				showForm={true}
				// onShowForm={showFormHandler} REMOVED FROM COMPONENT
				data={data}
			/>
			{loading && <Loading />}
			{data && <Success data={data.data} message={data.message} />}
			{error && <Error error={error} />}
		</PageWrapper>
	)
}

export default SupervisorJobVisit
