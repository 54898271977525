import React from 'react'
import { ICONS, ICONS_DEFAULT } from '../../../../constants/icons'

const InitialFormCategory = ({
	category,
	showCategory,
	onShowCategory,
	onUpdateCategoryItemRank,
	onCommentsChange,
}) => {
	return (
		<div
			className={`rounded ${
				showCategory === category.name
					? 'bg-romanLight1 dark:bg-romanDark3'
					: 'bg-romanLight3 dark:bg-romanDark2'
			}`}
		>
			<div
				className='flex space-x-2 items-center  font-semibold pl-4 py-2 border-b border-romanDarkBorder1 dark:border-romanLightBorder1 '
				onClick={() => onShowCategory(category.name)}
			>
				<div
					className={`text-xl ${
						category.isValidated ? 'text-green-700' : 'text-rose-600'
					}`}
				>
					{category.isValidated
						? ICONS.default.checkMark
						: ICONS_DEFAULT.errorSimple}
				</div>
				<div
					className={
						category.isValidated
							? 'text-romanLightTextInactive dark:text-romanDarkTextInactive'
							: 'text-romanLightTextActive dark:text-romanDarkTextActive'
					}
				>
					{category.name}
				</div>
			</div>
			{showCategory === category.name && (
				<div>
					{category.items.map((item, idx) => (
						<div
							key={idx}
							className='flex flex-col md:flex-row  pl-1 text-xs md:text-md md:pl-6 py-1'
						>
							<div className='w-full md:w-1/2 '>{item.name}</div>
							<div className='flex w-full py-2 md:w-1/2 justify-evenly items-center font-bold'>
								{Object.entries(item.rankFormat).map(([label, value]) => (
									<div
										key={label}
										className='flex space-x-1 items-center justify-center'
										onClick={() =>
											onUpdateCategoryItemRank(category.name, item.name, value)
										}
									>
										<input
											type='radio'
											value={value}
											checked={item.rank === value}
											readOnly
										/>
										<label className='text-xs md:text-[12px]'>{label}</label>
									</div>
								))}
							</div>
						</div>
					))}
					<textarea
						className='w-full border-1 border-romanLightBorder1 dark:border-romanDarkBorder1 dark:bg-romanDark3 px-1 md:px-6 py-2 mt-2 text-sm'
						placeholder='Add comments here...'
						value={category.comments}
						onChange={(e) => onCommentsChange(e.target.value, category.name)}
					></textarea>
				</div>
			)}
		</div>
	)
}

export default InitialFormCategory
