import { REDUX_CONST } from '../../constants'

export const getAvailableEstimators = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.appConstants.getAvailableEstimators.request:
			return { loading: true }
		case REDUX_CONST.appConstants.getAvailableEstimators.success:
			return { data: action.payload }
		case REDUX_CONST.appConstants.getAvailableEstimators.fail:
			return { error: action.payload }
		default:
			return state
	}
}
export const getCogsWithoutJobsActions = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.appConstants.getCogsWithoutJobsActions.request:
			return { loading: true }
		case REDUX_CONST.appConstants.getCogsWithoutJobsActions.success:
			return { data: action.payload }
		case REDUX_CONST.appConstants.getCogsWithoutJobsActions.fail:
			return { error: action.payload }
		default:
			return state
	}
}
export const getDeferredRevenueActions = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.appConstants.getDeferredRevenueActions.request:
			return { loading: true }
		case REDUX_CONST.appConstants.getDeferredRevenueActions.success:
			return { data: action.payload }
		case REDUX_CONST.appConstants.getDeferredRevenueActions.fail:
			return { error: action.payload }
		default:
			return state
	}
}

export const getWeeklyProductionReport = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.appConstants.getWeeklyProductionReport.request:
			return { loading: true }
		case REDUX_CONST.appConstants.getWeeklyProductionReport.success:
			return { data: action.payload }
		case REDUX_CONST.appConstants.getWeeklyProductionReport.fail:
			return { error: action.payload }
		default:
			return state
	}
}
export const createPotentialBackcharge = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.appConstants.createPotentialBackcharge.request:
			return { loading: true }
		case REDUX_CONST.appConstants.createPotentialBackcharge.success:
			return { data: action.payload }
		case REDUX_CONST.appConstants.createPotentialBackcharge.fail:
			return { error: action.payload }
		case REDUX_CONST.appConstants.createPotentialBackcharge.reset:
			return { data: [] }
		default:
			return state
	}
}
export const createJobSiteVisit = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.appConstants.createJobSiteVisit.request:
			return { loading: true }
		case REDUX_CONST.appConstants.createJobSiteVisit.success:
			return { data: action.payload }
		case REDUX_CONST.appConstants.createJobSiteVisit.fail:
			return { error: action.payload }
		case REDUX_CONST.appConstants.createJobSiteVisit.reset:
			return {}
		default:
			return state
	}
}
export const customerConversation = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.appConstants.getCustomerConversation.request:
			return { loading: true }
		case REDUX_CONST.appConstants.getCustomerConversation.success:
			return { data: action.payload }
		case REDUX_CONST.appConstants.getCustomerConversation.fail:
			return { error: action.payload }
		default:
			return state
	}
}
export const createCustomerMessage = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.appConstants.createCustomerMessage.request:
			return { loading: true }
		case REDUX_CONST.appConstants.createCustomerMessage.success:
			return { data: action.payload }
		case REDUX_CONST.appConstants.createCustomerMessage.fail:
			return { error: action.payload }
		case REDUX_CONST.appConstants.createCustomerMessage.reset:
			return { data: [] }
		default:
			return state
	}
}
