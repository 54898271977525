exports.appConstants = {
	getAvailableEstimators: {
		request: 'GET_AVAILABLE_ESTIMATORS_REQUEST',
		success: 'GET_AVAILABLE_ESTIMATORS_SUCCESS',
		fail: 'GET_AVAILABLE_ESTIMATORS_FAIL',
	},
	getDeferredRevenueActions: {
		request: 'GET_DEFERRED_REVENUE_ACTIONS_REQUEST',
		success: 'GET_DEFERRED_REVENUE_ACTIONS_SUCCESS',
		fail: 'GET_DEFERRED_REVENUE_ACTIONS_FAIL',
	},
	getCogsWithoutJobsActions: {
		request: 'GET_COGS_WITHOUT_JOBS_ACTIONS_REQUEST',
		success: 'GET_COGS_WITHOUT_JOBS_ACTIONS_SUCCESS',
		fail: 'GET_COGS_WITHOUT_JOBS_ACTIONS_FAIL',
	},
	getWeeklyProductionReport: {
		request: 'GET_WEEKLY_PRODUCTION_REPORT_REQUEST',
		success: 'GET_WEEKLY_PRODUCTION_REPORT_SUCCESS',
		fail: 'GET_WEEKLY_PRODUCTION_REPORT_FAIL',
	},
	createPotentialBackcharge: {
		request: 'CREATE_POTENTIAL_BACKCHARGE_REQUEST',
		success: 'CREATE_POTENTIAL_BACKCHARGE_SUCCESS',
		fail: 'CREATE_POTENTIAL_BACKCHARGE_FAIL',
		reset: 'CREATE_POTENTIAL_BACKCHARGE_RESET',
	},
	createJobSiteVisit: {
		request: 'CREATE_JOB_SITE_VISIT_REQUEST',
		success: 'CREATE_JOB_SITE_VISIT_SUCCESS',
		fail: 'CREATE_JOB_SITE_VISIT_FAIL',
		reset: 'CREATE_JOB_SITE_VISIT_RESET',
	},
	createCustomerMessage: {
		request: 'CREATE_CUSTOMER_MESSAGE_REQUEST',
		success: 'CREATE_CUSTOMER_MESSAGE_SUCCESS',
		fail: 'CREATE_CUSTOMER_MESSAGE_FAIL',
		reset: 'CREATE_CUSTOMER_MESSAGE_RESET',
	},
	getCustomerConversation: {
		request: 'GET_CUSTOMER_CONVERSATION_REQUEST',
		success: 'GET_CUSTOMER_CONVERSATION_SUCCESS',
		fail: 'GET_CUSTOMER_CONVERSATION_FAIL',
	},
}
