//marketing-scorecard.js
import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import {
	KPIBadge,
	KPILineChart,
	KPIMenu,
	KPIBarChart,
	PageWrapper,
	ScorecardLinks,
	ScorecardHeader,
	Tooltip,
} from '../../../components'
import {
	CONST_ADD,
	CONST_DELETE,
	CONST_IO,
	CONST_ROMAN_KPI,
	GEN_CONST,
} from '../../../constants/general'
import { ICONS } from '../../../constants/icons'
import { useDispatch, useSelector } from 'react-redux'
import { getScorecardData } from '../../../redux/actions/'
import AppointmentSet from './appointments-set'
import Toggle from 'react-toggle'
import 'react-toggle/style.css' // for ES6 modules

const INITIAL_EXCLUSION_FILTERS = [
	{ key: 'eventType', value: 'salesmanLeadCreated' },
]
const INITIAL_INCLUSION_FILTERS = [
	'Marina Garcia',
	'Erica Ayoub-Masser',
	'Leo Korch',
]
const calcPercent = (e) => Math.floor((e.value / e.target) * 100)
// Filtering the CONST_ROMAN_KPI to include only those objects where department is 'Marketing'
const eventTypes = Object.values(CONST_ROMAN_KPI)
	.filter((kpi) => kpi.department?.toLowerCase() === 'marketing')
	.map((kpi) => kpi.eventType)

const phaseChanges = ['Appointment Set']
// const phaseChanges = []

const getDayAbbreviation = (date) => {
	const dayNames = ['S', 'M', 'T', 'W', 'TH', 'F', 'SA']
	return dayNames[date.getDay()]
}

const initializeChartData = (label) => {
	return ['S', 'M', 'T', 'W', 'TH', 'F', 'SA'].map((day) => ({
		day,
		[label]: 0,
	}))
}

function processData(data) {
	// Objects to hold the processed data, keyed by sales rep.
	const leadsDataBySource = {}
	const appointmentsDataByContact = {}

	data.forEach((event) => {
		const { eventContact, leadSource, eventType, phase } = event

		// Initialize the record for this salesRep if it doesn't already exist
		if (!leadsDataBySource[leadSource]) {
			leadsDataBySource[leadSource] = 0
		}
		if (!appointmentsDataByContact[eventContact]) {
			appointmentsDataByContact[eventContact] = 0
		}

		// Increment the relevant counter based on the event type
		if (
			[
				'nonDigitalLeadGenerated',
				'nonDigitalLeadCreated',
				'digitalLeadGenerated',
				'salesmanLeadCreated',
			].includes(eventType)
		) {
			leadsDataBySource[leadSource]++
		} else if (
			eventType === 'appointmentSet' ||
			eventType === 'initialAppointmentSet' ||
			(eventType === 'phaseChange' && phase === 'Appointment Set')
		) {
			appointmentsDataByContact[eventContact]++
		}
	})

	// Convert the data into arrays suitable for charting
	const leadsGeneratedData = Object.keys(leadsDataBySource)
		.map((leadSource) => ({
			leadSource,
			leadsGenerated: leadsDataBySource[leadSource],
		}))
		.sort((a, b) => b.leadsGenerated - a.leadsGenerated) // Sort by leadsGenerated descending

	const appointmentsSetData = Object.keys(appointmentsDataByContact)
		.map((eventContact) => ({
			eventContact,
			appointmentsSet: appointmentsDataByContact[eventContact],
		}))
		.sort((a, b) => b.appointmentsSet - a.appointmentsSet) // Sort by appointmentsSet descending

	return { appointmentsSetData, leadsGeneratedData }
}
// Modify ToggleISTFilter to accept props
const ToggleISTFilter = ({ istFilter, setISTFilter }) => {
	const handleChange = () => {
		setISTFilter((prevFilter) => !prevFilter)
	}

	return (
		<Tooltip
			enabled={true}
			message='Turn on to show only IST performance.'
			className={`w-1/2 items-center justify-end text-inherit font-normal`}
		>
			<div className='flex items-center space-x-2 font-bold text-sm'>
				<div>{istFilter ? 'IST Only' : 'All Data'}</div>
				<Toggle checked={istFilter} onChange={handleChange} icons={false} />
			</div>
		</Tooltip>
	)
}
const MarketingScorecard = () => {
	const dispatch = useDispatch()
	const scoreCardDataRdx = useSelector((state) => state.scorecardData)

	// const leaderboardDataRdx = useSelector((state) => state.leaderboardData)
	// const [leaderBoardType, setLeaderBoardType] = useState('chart')
	const [activeChartType, setActiveChartType] = useState('line')
	const [switchInterval, setSwitchInterval] = useState(15)
	const [istFilter, setISTFilter] = useState(true)
	// const [switchLeaderboardInterval, setSwitchLeaderboardInterval] = useState(15)
	const [play, setPlay] = useState(true)
	const [leadsGenerated, setLeadsGenerated] = useState([])
	const [appointmentsSet, setAppointmentsSet] = useState([])

	// const [playLeaderboard, setPlayLeaderboard] = useState(true)
	const [weekOffset, setWeekOffset] = useState(0)
	const [kpiData, setKpiData] = useState({
		kpis: [],
		kpiLineCharts: [],
		kpiBarCharts: [],
	})
	const weekOffsetHandler = (direction) => {
		setWeekOffset((currentOffset) => {
			if (direction === CONST_ADD && currentOffset < 0) {
				return currentOffset + 1
			} else if (direction === CONST_DELETE && currentOffset > -12) {
				return currentOffset - 1
			}
			return currentOffset
		})
	}
	useEffect(() => {
		let intervalId

		if (play) {
			intervalId = setInterval(() => {
				setActiveChartType((prevType) => (prevType === 'line' ? 'bar' : 'line'))
			}, switchInterval * 1000) // Switch chart type based on switchInterval
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId) // Clear interval on component unmount or if play is set to false
			}
		}
	}, [switchInterval, play]) // Depend on switchInterval and play

	const togglePlayHandler = () => {
		setPlay((prevPlay) => !prevPlay)
	}

	const switchIntervalHandler = (type) => {
		setSwitchInterval((prevInterval) => {
			if (type === CONST_ADD && prevInterval < 60) {
				return prevInterval + 1
			} else if (type !== CONST_ADD && prevInterval > 1) {
				return prevInterval - 1
			}
			return prevInterval // Return the current value if no change is needed
		})
	}

	useEffect(() => {
		// console.log(eventTypes)
		dispatch(
			getScorecardData({
				phaseChanges,
				eventTypes,
				key: 'marketing',
				weekOffset,
			}),
		)
	}, [dispatch, weekOffset])

	useEffect(() => {
		if (scoreCardDataRdx.marketing) {
			// console.log(scoreCardDataRdx.marketing)
			const leadsGeneratedData = initializeChartData('leadsGenerated')
			const appointmentsSetData = initializeChartData('appointmentsSet')
			// Filter the .marketing array
			// Initially filter out events based on exclusion criteria
			const initialFilteredEvents = scoreCardDataRdx.marketing.filter(
				(event) => {
					// if IST filter is set, filter out the leads that are
					// created by estimators
					if (istFilter) {
						return !INITIAL_EXCLUSION_FILTERS.some(
							(filter) => event[filter.key] === filter.value,
						)
					}
					// if the IST filter is not on, keep the event
					return true
				},
			)

			// Further filter based on inclusion criteria if istFilter is true
			const filteredEvents = initialFilteredEvents.filter((event) => {
				// If IST filter is active and the event type is 'appointmentSet',
				// check if the contact is in the inclusion list
				if (
					istFilter &&
					(event.eventType === 'appointmentSet' ||
						event.eventType === 'initialAppointmentSet' ||
						(event.eventType === 'phaseChange' &&
							event.phase === 'Appointment Set'))
				) {
					return INITIAL_INCLUSION_FILTERS.includes(event.eventContact)
				}
				// If IST filter is not active or the event is not of type
				// 'appointmentSet', include the event
				return true
			})

			filteredEvents.forEach((event) => {
				const eventDate = new Date(event.timeStamp)
				const dayAbbrev = getDayAbbreviation(eventDate)
				const dayIndex = leadsGeneratedData.findIndex(
					(day) => day.day === dayAbbrev,
				)

				if (
					[
						'nonDigitalLeadGenerated',
						'nonDigitalLeadCreated',
						'digitalLeadGenerated',
						'salesmanLeadCreated',
					].includes(event.eventType)
				) {
					leadsGeneratedData[dayIndex].leadsGenerated++
				} else if (
					event.eventType === 'appointmentSet' ||
					event.eventType === 'initialAppointmentSet' ||
					(event.eventType === 'phaseChange' &&
						event.phase === 'Appointment Set')
				) {
					appointmentsSetData[dayIndex].appointmentsSet++
				}
			})

			// Assuming scoreCardDataRdx.marketing is an array of events
			const appointmentsSet = filteredEvents.filter(
				(event) =>
					event.eventType === 'appointmentSet' ||
					event.eventType === 'initialAppointmentSet' ||
					(event.eventType === 'phaseChange' &&
						event.phase === 'Appointment Set'),
			).length
			const leadsGenerated = filteredEvents.filter(
				(event) =>
					event.eventType === 'nonDigitalLeadGenerated' ||
					event.eventType === 'nonDigitalLeadCreated' ||
					event.eventType === 'digitalLeadGenerated' ||
					event.eventType === 'salesmanLeadCreated',
			).length
			const conversionRate = appointmentsSet / Math.max(leadsGenerated, 1) // To avoid division by zero

			// debugging
			const appointmentsSetEvents = filteredEvents.filter(
				(event) =>
					event.eventType === 'appointmentSet' ||
					event.eventType === 'initialAppointmentSet' ||
					(event.eventType === 'phaseChange' &&
						event.phase === 'Appointment Set'),
			)
			const leadsGeneratedEvents = filteredEvents.filter(
				(event) =>
					event.eventType === 'nonDigitalLeadGenerated' ||
					event.eventType === 'nonDigitalLeadCreated' ||
					event.eventType === 'digitalLeadGenerated' ||
					event.eventType === 'salesmanLeadCreated',
			)
			console.log('Appointments Set: ', appointmentsSetEvents)
			console.log('Leads Generated: ', leadsGeneratedEvents)

			setLeadsGenerated(leadsGeneratedEvents)
			setAppointmentsSet(appointmentsSetEvents)

			const mappedLeads = leadsGeneratedEvents.map((lead) => lead.jobID)
			//console.log(mappedLeads)

			// Now prepare KPI data
			const newKpis = [
				{
					kpi: 'Leads Generated',
					type: GEN_CONST.types.number,
					value: leadsGenerated,
					target: 137, // Assuming a static target, replace with dynamic if needed
					icon: ICONS.default.peopleRoof,
				},
				{
					kpi: 'Appointments Set',
					type: GEN_CONST.types.number,
					value: appointmentsSet,
					target: 45, // Assuming a static target, replace with dynamic if needed
					icon: ICONS.data.calendarCheckFill,
				},
				{
					kpi: 'Conversion Rate',
					type: GEN_CONST.types.percent,
					value: conversionRate,
					target: 0.75, // Assuming a static target, replace with dynamic if needed
					icon: ICONS.money.dollarFunnel,
				},
			].map((e) => ({
				...e,
				percentToGoal: calcPercent(e),
			}))

			const updatedKpiLineCharts = [
				{
					kpi: `INCOMING LEADS`,
					icon: ICONS.default.peopleRoof,
					dataKey: 'leadsGenerated',
					xDataKey: 'day',
					data: leadsGeneratedData,
				},
				{
					kpi: `APPT'S SET`,
					icon: ICONS.data.calendarCheck,
					dataKey: 'appointmentsSet',
					xDataKey: 'day',
					data: appointmentsSetData,
				},
			]

			const data = processData(filteredEvents)

			const updatedKpiBarCharts = [
				{
					kpi: `INCOMING LEADS`,
					icon: ICONS.default.peopleRoof,
					dataKey: 'leadsGenerated',
					xDataKey: 'leadSource',
					data: data.leadsGeneratedData,
					tickFormatter: false,
				},
				{
					kpi: `APPT'S SET`,
					icon: ICONS.data.calendarCheck,
					dataKey: 'appointmentsSet',
					xDataKey: 'eventContact',
					data: data.appointmentsSetData,
					tickFormatter: true,
				},
			]

			setKpiData({
				kpis: newKpis,
				kpiLineCharts: updatedKpiLineCharts,
				kpiBarCharts: updatedKpiBarCharts,
			})
			console.log(kpiData)
		}
	}, [scoreCardDataRdx, INITIAL_EXCLUSION_FILTERS, istFilter])
	useEffect(() => {
		// io('http://localhost:5000')
		// Connect to the Socket.IO server
		// Replace 'http://localhost:5000' with your server's URL
		const socket = io('https://www.nvoqe.com', { transports: ['websocket'] })
		// Setup event listeners

		socket.on(CONST_IO.events.EVENTS, (data) => {
			if (data.action === CONST_IO.actions.EVENT_CREATED && weekOffset === 0) {
				const event = data.payload
				if (
					event.eventType === 'nonDigitalLeadGenerated' ||
					event.eventType === 'nonDigitalLeadCreated' ||
					event.eventType === 'digitalLeadGenerated' ||
					event.eventType === 'salesmanLeadCreated' ||
					event.eventType === 'appointmentSet' ||
					event.eventType === 'initialAppointmentSet' ||
					(event.eventType === 'phaseChange' &&
						event.phase === 'Appointment Set')
				) {
					// console.log('Sending marketing dispatch...')
					// console.log(eventTypes)
					dispatch(
						getScorecardData({
							phaseChanges,
							eventTypes,
							key: 'marketing',
						}),
					)
				}
			}
		})
		// Clean up the effect
		return () => socket.disconnect()
	}, [])
	return (
		<PageWrapper title='Scorecards' links={<ScorecardLinks />}>
			<ScorecardHeader
				title={'MARKETING SCOREBOARD'}
				component={
					<ToggleISTFilter istFilter={istFilter} setISTFilter={setISTFilter} />
				}
			/>

			<KPIMenu
				weekOffset={weekOffset}
				onSetWeekOffset={weekOffsetHandler}
				play={play}
				onSwitchIntervalHandler={switchIntervalHandler}
				onTogglePlayHandler={togglePlayHandler}
				switchInterval={switchInterval}
				buttons={
					<div className='flex space-x-4'>
						<button
							className={`px-4 py-2 ${
								activeChartType === 'line'
									? 'bg-romanRedScorecard text-white '
									: 'bg-romanGoldScorecard text-black'
							}  rounded hover:bg-opacity-80`}
							onClick={() => setActiveChartType('line')}
						>
							Line Chart
						</button>
						<button
							className={`px-4 py-2 ${
								activeChartType === 'bar'
									? 'bg-romanRedScorecard text-white '
									: 'bg-romanGoldScorecard text-black'
							}  rounded hover:bg-opacity-80`}
							onClick={() => setActiveChartType('bar')}
						>
							Bar Chart
						</button>
					</div>
				}
			/>

			<div className='grid grid-cols-1 lg:grid-cols-10 gap-6  py-2 px-6 lg:px-0 text-justify text-romanDarkTextInactive dark:text-romanDarkTextActive'>
				<div className=' col-span-1 lg:col-span-4 flex flex-col h-full justify-between'>
					{kpiData.kpis.map((e, idx) => (
						<KPIBadge
							key={idx}
							percentToGoal={e.percentToGoal}
							icon={e.icon}
							kpi={e.kpi}
							valueType={e.type}
							goal={e.target}
							value={e.value}
						/>
					))}
				</div>
				<div className=' col-span-1 lg:col-span-6'>
					{activeChartType === 'line' &&
						kpiData.kpiLineCharts.map((e, idx) => (
							<KPILineChart
								dataKeyX={e.xDataKey}
								key={idx}
								kpi={e.kpi}
								icon={e.icon}
								dataKeyLine={e.dataKey}
								data={e.data}
							/>
						))}
					{activeChartType === 'bar' &&
						kpiData.kpiBarCharts.map((e, idx) => (
							<KPIBarChart
								dataKeyX={e.xDataKey}
								key={idx}
								kpi={e.kpi}
								icon={e.icon}
								dataKeyBar={e.dataKey}
								data={e.data}
								tickFormatter={e.tickFormatter}
							/>
						))}
				</div>
			</div>
			{leadsGenerated.length > 0 && (
				<React.Fragment>
					<div className='py-3 font-bold text-3xl lg:text-justify md:text-4xl text-romanLightTextActive dark:text-romanDarkTextActive'>
						Leads Generated
					</div>
					<div className='grid grid-cols-1 gap-1 text-xs justify-center items-center text-center text-romanGray800Scorecard '>
						<div className='grid grid-cols-9 p-4 w-full bg-romanGray800Scorecard text-white rounded-lg text-xs  font-bold justify-items-center border-b-2 border-romanGray800Scorecard '>
							<div className=''>Date</div>
							<div className=''>Job ID</div>
							<div className=''>Template</div>
							<div className=''>Phase</div>
							<div className=''>Lead Source</div>
							<div className=''>Lead Type</div>
							<div className=''>Event Contact</div>
							<div className=''>Event Type</div>
							<div className=''>Sales Rep</div>
						</div>
						<div className='overflow-scroll  max-h-[600px]'>
							{leadsGenerated.map((lead, idx) => (
								<div
									key={idx}
									className='bg-romanGray200Scorecard grid grid-cols-9 p-2 w-full justify-items-center border-1 border-romanGray800Scorecard rounded-lg'
								>
									<div>{lead.date}</div>
									<div>{lead.jobID}</div>
									<div>{lead.template}</div>
									<div>{lead.phase}</div>
									<div>{lead.leadSource}</div>
									<div>{lead.leadType}</div>
									<div>{lead.eventContact}</div>
									<div>{lead.eventType}</div>
									<div>{lead.salesRep}</div>
								</div>
							))}
						</div>
					</div>
				</React.Fragment>
			)}
			{appointmentsSet.length > 0 && (
				<AppointmentSet appointmentsSet={appointmentsSet} />
			)}
		</PageWrapper>
	)
}

export default MarketingScorecard
