import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../components/page-components/apps/production/table'
import ClassSummary from '../../components/page-components/apps/production/class-summary'
import Loading from '../../components/page-components/apps/deferred-revenue-actions/loading'
import Error from '../../components/page-components/apps/deferred-revenue-actions/error'
import { getWeeklyProductionReport } from '../../redux/actions/appActions'
import PageWrapper from '../../components/layout/sage-page-wrapper'
import { CONST_IO } from '../../constants/general'
import Message from '../../components/page-components/apps/production/message'

const WeeklyProductionReport = () => {
	const [ioMessage, setIOMessage] = useState('')
	const [classSummary, setClassSummary] = useState([])
	const [jobs, setJobs] = useState([])
	const dispatch = useDispatch()
	const weeklyProductionReportRdx = useSelector(
		(state) => state.getWeeklyProductionReport,
	)
	const { data, loading, error } = weeklyProductionReportRdx

	useEffect(() => {
		console.log(weeklyProductionReportRdx)
		return () => {}
	}, [weeklyProductionReportRdx])

	useEffect(() => {
		dispatch(getWeeklyProductionReport())

		return () => {}
	}, [])

	useEffect(() => {
		if (data?.message) {
			setIOMessage(data.message)
		}

		return () => {}
	}, [data])

	useEffect(() => {
		// io('http://localhost:5000')
		// Connect to the Socket.IO server
		// Replace 'http://localhost:5000' with your server's URL
		const socket = io('http://localhost:5000', { transports: ['websocket'] })
		//const socket = io('https://www.nvoqe.com', { transports: ['websocket'] })
		// Setup event listeners

		socket.on(CONST_IO.events.PRODUCTION_REPORT, (data) => {
			if (data.action === CONST_IO.actions.UPDATED_JOB_DETAILS) {
				const { message, data: newJobs } = data.payload
				//console.log(data.payload)
				setIOMessage(message)
				setJobs((prevJobs) => {
					const updatedJobs = [...prevJobs]
					newJobs.forEach((newJob) => {
						const jobIndex = prevJobs.findIndex(
							(job) => job.jobId === newJob.jobId,
						)
						if (jobIndex !== -1) {
							// If job exists, replace it
							updatedJobs[jobIndex] = newJob
						} else {
							// If job does not exist, add it
							updatedJobs.push(newJob)
						}
					})
					return updatedJobs
				})
			} else if (data.action === CONST_IO.actions.UPDATED_JOB_SUMMARY) {
				const { data: summaryData } = data.payload
				if (summaryData && summaryData.classSummary) {
					setClassSummary(summaryData.classSummary)
				} else {
					console.error(
						'Class summary data is missing or malformed:',
						data.payload,
					)
				}
			}
		})

		// Clean up the effect
		return () => socket.disconnect()
	}, [])

	return (
		<PageWrapper showNavigation={false}>
			{loading && (
				<Message message={'Please wait while we fetch your data...'} />
			)}

			{classSummary.length > 0 && <ClassSummary data={classSummary} />}

			{jobs?.length > 0 && <Table data={jobs} />}
			{jobs?.length === 0 && !loading && (
				<Message message={'No Jobs Loaded.'} />
			)}

			{error && <Error error={error} />}
		</PageWrapper>
	)
}

export default WeeklyProductionReport

// set timeframe
// fetch final invoice data
// create a list of jobs by week
// grade and report the jobs
// summarize the jobs

// Start Function:
// --> Request received status 200
