import React from 'react'

const InitialFormHeader = ({
	jobId,
	salesRep,
	supervisor,
	siteZip,
	email,
	tearOffCrew,
	installCrew,
}) => {
	return (
		<>
			<div className='flex flex-wrap gap-3'>
				{[
					{ name: 'Job Id:', value: jobId },
					{ name: 'Site Zip:', value: siteZip },
					{ name: 'Supervisor:', value: supervisor },
					{ name: 'Supervisor Email:', value: email },
				].map((obj, idx) => (
					<div key={idx} className='flex gap-4 text-xs p-2  '>
						<div className='font-semibold'>{obj.name}</div>
						<div>{obj.value}</div>
					</div>
				))}
			</div>
			<div className='flex flex-wrap gap-3'>
				{[
					{ name: 'Estimator:', value: salesRep },
					{ name: 'Tear Off Crew:', value: tearOffCrew },
					{ name: 'Install Crew:', value: installCrew },
				].map((obj, idx) => (
					<div key={idx} className='flex gap-4 text-xs p-2  '>
						<div className='font-semibold'>{obj.name}</div>
						<div>{obj.value}</div>
					</div>
				))}
			</div>
		</>
	)
}

export default InitialFormHeader
