// table.js
import React from 'react'
import TableItem from './table-item'

const Table = ({ data }) => {
	return (
		<div className='py-2 text-xs grid gap-1'>
			<div className='text-sm'>
				The following jobs have both a final invoice and a balance in account
				23500
			</div>
			{/* Header */}
			<div className='grid grid-cols-3 font-bold'>
				<div>Job</div>
				<div>Deferred Amount</div>
				<div>Final Invoice Date</div>
			</div>
			{/* Rows */}
			{data?.data?.length > 0 &&
				data.data.map((row, idx) => (
					<TableItem
						key={idx}
						job={row.job}
						deferredAmount={0 - row.deferredAmount}
						finalInvoiceDate={row.finalInvoiceDate}
					/>
				))}
		</div>
	)
}

export default Table
