import React from 'react'

const TableItem = ({ row }) => {
	return (
		<div className='grid grid-cols-7  items-center'>
			<div className='text-center'>{row.RECORDTYPE}</div>
			<div className='text-center'>{row.DEPARTMENTID}</div>
			<div className='text-center'>{row.ACCOUNTNO}</div>

			<div className='text-right pr-[50%]'>
				{parseFloat(row.AMOUNT).toLocaleString(undefined, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				})}
			</div>

			<div className='text-center'>{row.ENTRY_DATE || 'N/A'}</div>
			<div className='text-center'>{row.VENDORNAME}</div>
			<div className='text-center'>{row.DESCRIPTION}</div>
		</div>
	)
}

export default TableItem
