import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import Table from '../../components/page-components/apps/cogs-without-jobs-actions/table'
import Loading from '../../components/page-components/apps/cogs-without-jobs-actions/loading'
import Error from '../../components/page-components/apps/cogs-without-jobs-actions/error'
import { getCogsWithoutJobsActions } from '../../redux/actions/appActions'
import PageWrapper from '../../components/layout/sage-page-wrapper'

const COGSActions = () => {
	const dispatch = useDispatch()
	const deferredRevenueActionsRdx = useSelector(
		(state) => state.getCogsWithoutJobsActions,
	)
	const { data, loading, error } = deferredRevenueActionsRdx

	// useEffect(() => {
	// 	return () => {}
	// }, [error, data, loading])

	useEffect(() => {
		dispatch(getCogsWithoutJobsActions())

		return () => {}
	}, [])

	return (
		<PageWrapper showNavigation={false}>
			{loading && <Loading />}
			{data?.data?.length > 0 && <Table data={data} />}
			{data?.data?.length === 0 && (
				<div className='flex flex-col justify-center items-center'>
					<div className='w-full text-center pb-4 font-bold'>
						Congrats! Nothing to do here!
					</div>
					<img
						src='/images/sage/taskCompleted.png'
						alt=''
						className='block mx-auto md:mx-0 '
					/>
				</div>
			)}

			{error && <Error error={error} />}
		</PageWrapper>
	)
}

export default COGSActions
