const RANK_FORMAT = {
	default: { Yes: 1, No: -1, Partial: 0.5, 'N/A': 0 },
}

export const CATEGORIES = [
	{
		name: 'PPE & Uniform',
		isValidated: false,
		comments: '',
		items: [
			{
				name: `Roman Uniforms Worn`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{ name: `Harnesses Worn`, rank: null, rankFormat: RANK_FORMAT.default },
			{
				name: `Safety Tethering (Tied Off)`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Hard Hats Worn (when on ground)`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Safety Glasses Worn`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `OSHA Compliant Footwear Worn`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
		],
	},
	{
		name: 'Equipment & Material',
		isValidated: false,
		comments: '',
		items: [
			{
				name: `Ladder Inspection Checklist Completed`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Ladder Extends 36" Past Eave and Is Tied Off`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Ladder Has Secure Foot Placement`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Equipment Securely Positioned on Roof`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Nail Gun Used Properly`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Hammer Used Properly`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Materials Disposed of Properly`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Worksite Maintained in Safe Condition`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Gas Cans and Compressors Kept in Safe Zone`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Vehicles Parked Safely`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
		],
	},
	{
		name: 'Site Prep',
		isValidated: false,
		comments: '',
		items: [
			{
				name: `Tarps Placed for Debris`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Air Conditioning Units Protected`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Garage Doors and Coach Lights Protected`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Driveways Protected`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Vegetation and Landscape Protected`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Pool Enclosures Protected`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
		],
	},
	{
		name: 'Site Organization and Cleanliness',
		isValidated: false,
		comments: '',
		items: [
			{
				name: `Materials Neatly Stacked`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Tools and Equipment Stored Properly`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Debris and Waste Management`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Clear Walkways and Access Points`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `General Cleanliness`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
		],
	},
	{
		name: 'Marketing',
		isValidated: false,
		comments: '',
		items: [
			{
				name: `Current Roman Yard Sign Displayed`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `New Yard Sign Placed`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Door Hanger Placed on Homeowner's Door`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
			{
				name: `Door Hangers Placed on Neighboring Doors`,
				rank: null,
				rankFormat: RANK_FORMAT.default,
			},
		],
	},
]
