//error.js
import React from 'react'

const Error = ({ error }) => {
	return (
		<div className='p-10 w-full text-center text-xl '>
			{JSON.stringify(error) === `"Request failed with status code 503"` ? (
				<div className='flex flex-col items-center justify-center space-y-2'>
					<div className='text-lg font-semibold mx-auto'>Too Slow!</div>
					<div className='text-sm font-semibold mx-auto'>
						We had 30 seconds and almost made it...
					</div>
					<div className='text-sm mx-auto'>
						Give it another try, please? Click refresh ...
					</div>
					<img src='/images/sage/tooSlow.gif' />
				</div>
			) : (
				JSON.stringify(error)
			)}
		</div>
	)
}

export default Error
