//estimator-item.js
import React from 'react'

const EstimatorItem = ({ estimator, rank }) => {
	console.log(estimator)
	const formatName = (fullName) => {
		const nameParts = fullName.split(' ') // Split the name into parts
		if (nameParts.length > 1) {
			return `${nameParts[0]} ${nameParts[1].charAt(0)}.` // Return first name and initial of the last name
		}
		return fullName // Return the full name if it doesn't have two parts
	}
	return (
		<div className='w-[225px] p-3 rounded-lg border-1 border-romanLightBorder1 dark:border-romanDarkBorder1'>
			<div className='flex justify-between items-center text-xl'>
				<div>{formatName(estimator.estimator)}</div> {/* Use formatted name */}
				<div>{rank}</div>
			</div>

			<div className='mt-2 flex justify-between items-center text-xs'>
				<div className='text-sm'>Local Win Rate</div>
				<div className='pl-4'>
					{`${estimator.configuration['Local Win Rate'].percent}%`}
				</div>
			</div>
			<div className='flex justify-between items-center text-xs'>
				<div className='pl-4'>Ratio</div>
				<div className='pl-4'>
					{estimator.configuration['Local Win Rate'].rate}
				</div>
			</div>

			<div className='mt-2 flex justify-between items-center text-xs'>
				<div className='text-sm'>Total Win Rate</div>
				<div className='pl-4'>
					{`${estimator.configuration['Total Win Rate'].percent}%`}
				</div>
			</div>
			<div className='flex justify-between items-center text-xs'>
				<div className='pl-4'>Ratio</div>
				<div className='pl-4'>
					{estimator.configuration['Total Win Rate'].rate}
				</div>
			</div>
		</div>
	)
}

export default EstimatorItem
