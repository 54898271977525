import React, { useState, useMemo } from 'react'

import ClassSummaryItem from './class-summary-item'

const sortOptions = [
	{ value: 'className', text: 'Class Name' },
	{ value: 'totalRevenue', text: 'Total Revenue' },
	{ value: 'totalCogs', text: 'Total COGS' },
	{ value: 'totalExpenses', text: 'Total Expenses' },
	{ value: 'totalGM', text: 'Total GM' },
	{ value: 'totalGMPercent', text: 'Total GM%' },
]

const ClassSummary = ({ data }) => {
	const [sortFields, setSortFields] = useState({
		primary: '',
		secondary: '',
	})
	const [sortDirections, setSortDirections] = useState({
		primary: 'ascending',
		secondary: 'ascending',
	})

	const handleSortFieldChange = (field, value) => {
		setSortFields((prev) => ({ ...prev, [field]: value }))
	}

	const handleSortDirectionChange = (field, value) => {
		setSortDirections((prev) => ({ ...prev, [field]: value }))
	}

	const sortedData = useMemo(() => {
		let sortableItems = [...data] // Make a copy of the data
		const { primary, secondary } = sortFields
		const directions = sortDirections

		sortableItems.sort((a, b) => {
			let primaryResult = 0
			if (primary && a[primary] !== undefined && b[primary] !== undefined) {
				primaryResult =
					(a[primary] < b[primary] ? -1 : 1) *
					(directions.primary === 'ascending' ? 1 : -1)
				if (
					a[primary] === b[primary] &&
					secondary &&
					a[secondary] !== undefined &&
					b[secondary] !== undefined
				) {
					return (
						(a[secondary] < b[secondary] ? -1 : 1) *
						(directions.secondary === 'ascending' ? 1 : -1)
					)
				}
				return primaryResult
			}
			return 0
		})

		return sortableItems
	}, [data, sortFields, sortDirections])

	return (
		<div className='py-2 text-xs grid gap-1'>
			<div className='text-sm'>Job PnL Summary - 10 days</div>
			<div className='py-2 grid grid-cols-2'>
				<select
					className='py-2 my-2 mx-3 px-4 bg-romanLight3 dark:bg-romanDark4 rounded-md text-romanLightTextActive dark:text-romanDarkTextActive'
					value={sortFields.primary}
					onChange={(e) => handleSortFieldChange('primary', e.target.value)}
				>
					<option value=''>Select Primary Sort</option>
					{sortOptions.map((option) => (
						<option key={option.value} value={option.value}>
							{option.text}
						</option>
					))}
				</select>
				<select
					className='py-2 my-2 mx-3 px-4 bg-romanLight3 dark:bg-romanDark4 rounded-md text-romanLightTextActive dark:text-romanDarkTextActive'
					value={sortDirections.primary}
					onChange={(e) => handleSortDirectionChange('primary', e.target.value)}
				>
					<option value='ascending'>Ascending</option>
					<option value='descending'>Descending</option>
				</select>
				<select
					className='py-2 my-2 mx-3 px-4 bg-romanLight3 dark:bg-romanDark4 rounded-md text-romanLightTextActive dark:text-romanDarkTextActive'
					value={sortFields.secondary}
					onChange={(e) => handleSortFieldChange('secondary', e.target.value)}
				>
					<option value=''>Select Secondary Sort</option>
					{sortOptions.map((option) => (
						<option key={option.value} value={option.value}>
							{option.text}
						</option>
					))}
				</select>
				<select
					className='py-2 my-2 mx-3 px-4 bg-romanLight3 dark:bg-romanDark4 rounded-md text-romanLightTextActive dark:text-romanDarkTextActive'
					value={sortDirections.secondary}
					onChange={(e) =>
						handleSortDirectionChange('secondary', e.target.value)
					}
				>
					<option value='ascending'>Ascending</option>
					<option value='descending'>Descending</option>
				</select>
			</div>
			{/* Header */}
			<div className='grid grid-cols-10 font-bold'>
				<div>Class Name</div>
				<div>Total Revenue</div>
				<div>Total COGS</div>
				<div>Total Expenses</div>
				<div>Total GM</div>
				<div>Total GM%</div>
			</div>
			{/* Rows */}
			{sortedData &&
				sortedData.length > 0 &&
				sortedData.map((row, idx) => <ClassSummaryItem key={idx} {...row} />)}
		</div>
	)
}

export default ClassSummary
