import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ICONS } from '../../../../constants/icons'
import { useDispatch } from 'react-redux'
import {
	createCustomerMessage,
	resetAppItem,
} from '../../../../redux/actions/appActions'

const InitialForm = ({ showForm, onShowForm, children, createSuccess }) => {
	const [message, setMessage] = useState('')
	const [leadType, setLeadType] = useState('')
	const [leadSource, setLeadSource] = useState('')
	const [cellPhoneNumber, setCellPhone] = useState('')
	const [homePhoneNumber, setHomePhone] = useState('')
	const [fullName, setFullName] = useState('')
	const [jobId, setJobId] = useState('')
	const [phase, setPhase] = useState('')
	const [sender, setSender] = useState('')

	const [error, setError] = useState('')
	const location = useLocation()
	const dispatch = useDispatch()

	useEffect(() => {
		if (location && location.search) {
			const params = new URLSearchParams(location.search)
			const lt = params.get('lt') || '' //Setting Type
			const ls = params.get('ls') || '' //Setting Type
			const cph = params.get('cph') || ''
			const hph = params.get('hph') || ''
			const fn = params.get('fn') || ''
			const jobId = params.get('jobId') || ''
			const phase = params.get('phase') || ''
			const sender = params.get('sender') || ''

			setLeadSource(ls)
			setLeadType(lt)
			setCellPhone(cph)
			setHomePhone(hph)
			setFullName(fn)
			setJobId(jobId)
			setPhase(phase)
			setSender(sender)
		}
		return () => {}
	}, [location])

	useEffect(() => {
		if (createSuccess?.message) {
			console.log('Should set empty')
			setMessage('')
			dispatch(resetAppItem('createCustomerMessage'))
		}

		return () => {}
	}, [createSuccess])

	/**
	 * Normalize a phone number to the format required for Twilio (+1XXXXXXXXXX).
	 *
	 * @param {string} phoneNumber - The phone number to normalize.
	 * @param {string} countryCode - The country code to prepend (default: "+1").
	 * @returns {string|null} - The normalized phone number or null if invalid.
	 */
	const normalizePhoneNumber = (phoneNumber, countryCode = '+1') => {
		if (!phoneNumber) return null

		// Remove all non-numeric characters
		const numericPhone = phoneNumber.replace(/\D/g, '')

		// Validate that the phone number has at least 10 digits
		if (numericPhone.length < 10) {
			console.error('Invalid phone number:', phoneNumber)
			return null
		}

		// Add country code if not already included
		if (!numericPhone.startsWith(countryCode.replace('+', ''))) {
			return `${countryCode}${numericPhone}`
		}

		// If the country code is already included
		return `+${numericPhone}`
	}

	const submitHandler = (e) => {
		e.preventDefault()

		setError('')

		const normalizedCellPhone = normalizePhoneNumber(cellPhoneNumber)
		if (!normalizedCellPhone) {
			setError('Invalid cell phone number. Please check and try again.')
			return
		}

		dispatch(
			createCustomerMessage({
				phoneNumber: normalizedCellPhone,
				message,
				user: sender,
			}),
		)
	}
	const showFormHandler = () => {
		onShowForm && onShowForm()
	}
	return (
		<div
			className={` p-10 rounded-xl bg-romanLight3 dark:bg-romanDark2 md:col-span-1 `}
		>
			<div className='text-lg pb-4'>
				<div className='flex justify-between'>
					<h4 className='font-semibold opacity-90'>
						{'Customer Conversation'}
					</h4>
					<div
						className='p-2 hover:bg-romanLight4 dark:hover:bg-romanDark3 rounded-full'
						onClick={showFormHandler}
					>
						{showForm ? ICONS.default.minus : ICONS.default.downChevron}
					</div>
				</div>
				<div className='opacity-50'>{'IST Tools'}</div>
			</div>
			{showForm && (
				<>
					<form onSubmit={submitHandler} className='space-y-4'>
						{error && (
							<p className='text-romanRed2 dark:text-romanGold1 text-sm'>
								{error}
							</p>
						)}
						<div className='flex flex-wrap gap-3'>
							{[
								{ name: 'Customer:', value: fullName },
								{ name: 'Job:', value: jobId },
								{ name: 'Lead Type:', value: leadType },
								{ name: 'Lead Source:', value: leadSource },
							].map((obj, idx) => (
								<div key={idx} className='flex gap-4 text-xs p-2  '>
									<div className='font-semibold'>{obj.name}</div>
									<div>{obj.value}</div>
								</div>
							))}
						</div>
						<div className='flex flex-wrap gap-3'>
							{[
								{ name: 'Cell Phone:', value: cellPhoneNumber },
								{ name: 'Home Phone:', value: homePhoneNumber },
								{ name: 'Phase:', value: phase },
							].map((obj, idx) => (
								<div key={idx} className='flex gap-4 text-xs p-2  '>
									<div className='font-semibold'>{obj.name}</div>
									<div>{obj.value}</div>
								</div>
							))}
						</div>

						{children}

						<div>
							<label className='block text-sm font-medium'>
								Message to customer:
							</label>
							<input
								type='text'
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								className='w-full p-2 border border-romanLightBorder1 dark:border-romanDarkBorder1 rounded    text-romanLightTextActive dark:text-romanDarkTextActive bg-romanLight1 dark:bg-romanDark3'
								placeholder='Enter message to customer'
							/>
						</div>

						<button
							type='submit'
							className='w-full p-2 bg-romanRed2 dark:bg-romanGold1 text-romanDarkTextActive dark:text-romanLightTextActive font-semibold rounded hover:bg-romanRed1 dark:hover:bg-romanGold2'
						>
							Send Message
						</button>
					</form>
				</>
			)}
		</div>
	)
}

export default InitialForm
