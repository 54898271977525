import React from 'react'

const TableItem = ({ job, deferredAmount, finalInvoiceDate }) => {
	return (
		<div className='grid grid-cols-3 items-center'>
			<div>{job}</div>
			<div>
				{deferredAmount.toLocaleString(undefined, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				})}
			</div>
			<div>{finalInvoiceDate || 'N/A'}</div>
		</div>
	)
}

export default TableItem
